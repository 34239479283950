import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { isFunctionExpression } from '@babel/types';
import { strict } from 'assert';
import { LargeTile, SmallTile } from './Components/Tiles.js';
import { API } from './API';
import { DataSeriesTile } from './Components/DataSeriesTile';

class App extends Component
{
    constructor(props)
    {
        super(props);
        this.state = { data: null };
        this.intervalID = null;

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount(){
        this.intervalID = setInterval(this.loadData, 5000);
        this.loadData();
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
    }

    loadData(){
        API.GetWeatherSummary(1, d => this.setState({data: d}));
    }

    render() {

        var content = (<div>No Data</div>);

        if(this.state.data)
        {
            content = (
                <div style={{ width: "100vw", overflow: "hidden" }}>
                    <div className="row">
                        <DataSeriesTile value={this.state.data.latestTemp} units="&deg;" subtext="Current" mode="temp" dataSeriesName="temperature" min={0} max={35}/>
                        <SmallTile value={this.state.data.minTemp} units="&deg;" subtext={"Min @ " + this.formatDate(this.convertUTCDate(this.state.data.minTempTime))} mode="temp"/>
                        <SmallTile value={this.state.data.maxTemp} units="&deg;" subtext={"Max @ " + this.formatDate(this.convertUTCDate(this.state.data.maxTempTime))} mode="temp"/>
                        <DataSeriesTile value={this.state.data.latestWindSpeed} units="km/h" subtext="Current wind" mode="wind" dataSeriesName="windspeed" min={0} max={30}/>
                        <SmallTile value={this.state.data.maxWindSpeed} units="km/h" subtext={"Max @ " + this.formatDate(this.convertUTCDate(this.state.data.maxWindSpeedTime))} mode="wind"/>
                        <SmallTile value={this.state.data.avgWindSpeed} units="km/h" subtext="Avg today" mode="wind"/>
                        <LargeTile value={this.state.data.totalRainfall} units="mm" subtext="Rainfall today" mode={this.state.data.isRaining ? "rain" : "norain"}/>
                        <DataSeriesTile value={this.state.data.latestHumidity} units="%" subtext="Humidity" mode="humidity" dataSeriesName="humidity" min={0} max={100}/>
                        <SmallTile value={this.state.data.minHumidity} units="%" subtext={"Min @ " + this.formatDate(this.convertUTCDate(this.state.data.minHumidityTime))} mode="humidity"/>
                        <SmallTile value={this.state.data.maxHumidity} units="%" subtext={"Max @ " + this.formatDate(this.convertUTCDate(this.state.data.maxHumidityTime))} mode="humidity"/>
                    </div>
                </div>
            );
        }

        return (<div className="App">
            {content}
        </div>
        );
    }

    convertUTCDate(DateString){
        return new Date(DateString.replace("T", " ") + " UTC")
    }

    formatDate(Date){
        var hours = Date.getHours();
        var meridian = "am";
        if(hours > 12)
        {
            hours -= 12;
            meridian = "pm";
        }
        if(hours == 12){
            meridian = "pm";
        }
        if(hours == 0)
        {
            hours = 12;
        }
        return hours + ":" + this.pad(Date.getMinutes()) + meridian;
    }

    pad(Str)
    {
        if(!Str.length)
        {
            Str = Str.toString();
        }
        if(Str.length < 2)
        {
            return "0" + Str;
        }
        return Str;
    }
    
}

export default App;
