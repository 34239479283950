import React from 'react';

var Colors = {
	LightBlue: [117, 201, 201],
	DarkBlue: [5, 71, 125],
	DarkGreen: [5, 125, 11],
	Orange: [209, 121, 27],
	DarkRed: [125, 23, 5],
	Purple: [73, 5, 125]
};

export function LargeTile(props){
	var bgColor = BGColorFromModeAndValue(props.mode, props.value);

	var sizeUnits = props.units != "°";

	return (
		<div className="col-12 tile tile-large" style={{backgroundColor: bgColor}}>
			<div className="tile-main">{props.value}<sup className={sizeUnits ? 'unit' : ''}>{props.units}</sup></div>
			{props.subtext ? <div className="tile-subtext">{props.subtext}</div> : ''}
		</div>
	);
}

export function SmallTile(props){
	var bgColor = BGColorFromModeAndValue(props.mode, props.value);

	var sizeUnits = props.units != "°";

	return (
		<div className="col-6 tile tile-small" style={{backgroundColor: bgColor}}>
			<div className="tile-main">{props.value}<sup className={sizeUnits ? 'unit' : ''}>{props.units}</sup></div>
			{props.subtext ? <div className="tile-subtext">{props.subtext}</div> : ''}
		</div>
	);
}

export function WindTile(props){
	var bgColor = BGColorFromModeAndValue(props.mode, props.value);

	var sizeUnits = props.units != "°";

	return (
		<div className="col-12 tile tile-small" style={{backgroundColor: bgColor}}>
			<div className="tile-main">{props.value}<sup className={sizeUnits ? 'unit' : ''}>{props.units}</sup></div>
			{props.subtext ? <div className="tile-subtext">{props.subtext}</div> : ''}
		</div>
	);
}

export function BGColorFromModeAndValue(Mode, Value)
{
	if(Mode)
	{
		if(Mode == 'temp')
		{
			return GetTemperatureColor(Value);
		}
		else if(Mode == "humidity")
		{
			return GetHumidityColor(Value);
		}
		else if(Mode == "wind")
		{
			return GetWindColor(Value);
		}
		else if(Mode == "norain")
		{
			return "rgb(122, 163, 235)";
		}
		else if(Mode == "rain")
		{
			return "rgb(5, 71, 125)";
		}
	}
	return "#FFFFFF";
}

function GetHumidityColor(Humidity)
{
	var lowColor = [209, 121, 27];
	var highColor = [5, 71, 125];
	return ArrayToRGB(InterpolateColor(lowColor, highColor, 0, 100, Humidity));
}

function GetWindColor(WindSpeed)
{
	var windSpeedThresholds = [
		{
			Min: 0,
			Max: 60,
			MinColor: Colors.DarkGreen,
			MaxColor: Colors.Orange
		},
		{
			Min: 60,
			Max: 100,
			MinColor: Colors.Orange,
			MaxColor: Colors.DarkRed
		}
	];

	return GetColorForValue(WindSpeed, windSpeedThresholds);
}

function GetTemperatureColor(Temp){

	var tempThresholds = [
		{
			Min: -3,
			Max: 6,
			MinColor: Colors.LightBlue,
			MaxColor: Colors.DarkBlue
		},
		{
			Min: 6,
			Max: 16,
			MinColor: Colors.DarkBlue,
			MaxColor: Colors.DarkGreen
		},
		{
			Min: 16,
			Max: 26,
			MinColor: Colors.DarkGreen,
			MaxColor: Colors.DarkGreen
		},
		{
			Min: 26,
			Max: 35,
			MinColor: Colors.DarkGreen,
			MaxColor: Colors.Orange
		},
		{
			Min: 35,
			Max: 40,
			MinColor: Colors.Orange,
			MaxColor: Colors.DarkRed
		},
		{
			Min: 40,
			Max: 46,
			MinColor: Colors.DarkRed,
			MaxColor: Colors.Purple
		}
	];

	return GetColorForValue(Temp, tempThresholds);
}

function GetColorForValue(Value, ColorThresholds){
	for(var i = 0;i < ColorThresholds.length;i++){
		if(Value <= ColorThresholds[i].Min){
			return ArrayToRGB(ColorThresholds[i].MinColor);
		}
		if(Value > ColorThresholds[i].Min && Value < ColorThresholds[i].Max){
			return ArrayToRGB(InterpolateColor(ColorThresholds[i].MinColor, ColorThresholds[i].MaxColor, ColorThresholds[i].Min, ColorThresholds[i].Max, Value));
		}
	}
	var last = ColorThresholds[ColorThresholds.length - 1];
	return ArrayToRGB(last.MaxColor);
}

function InterpolateColor(LowColor, HighColor, MinValue, MaxValue, Value){
	function InterpolateValue(LowMap, HighMap, LowValue, HighValue, Value) {
        var i = ((HighMap - LowMap) / (HighValue - LowValue));
        return LowMap + Math.round(i * (Value - LowValue));
    }

	var result = [InterpolateValue(LowColor[0], HighColor[0], MinValue, MaxValue, Value), 
		InterpolateValue(LowColor[1], HighColor[1], MinValue, MaxValue, Value),
		InterpolateValue(LowColor[2], HighColor[2], MinValue, MaxValue, Value)
	];
	return result;
}

function ArrayToRGB(Array){
	return 'rgb(' + Array[0] + ',' + Array[1] + ',' + Array[2] + ')';
}
