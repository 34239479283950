import React, { Component } from 'react';
import { BGColorFromModeAndValue } from './Tiles';
import { API } from './../API';

export class DataSeriesTile extends Component{
    constructor(props){
		super(props);
		
		this.intervalID = null;

		this.state = { series: [] };
		this.LoadSeries = this.LoadSeries.bind(this);
    }

	componentDidMount(){
        this.intervalID = setInterval(this.LoadSeries, 10000); //10 seconds
        this.LoadSeries();
    }

    componentWillUnmount(){
        clearInterval(this.intervalID);
    }

	LoadSeries(){
		API.GetSeriesData(1, this.props.dataSeriesName, d => { 
			d.forEach(dv => dv.parsedTime = new Date(dv.minTime.replace("T", " ") + " UTC"));
			this.setState({ series: d });
		});
	}

    render(){
        var bgColor = BGColorFromModeAndValue(this.props.mode, this.props.value);

		var sizeUnits = this.props.units != "°";

		return (
			<div className="col-12 tile tile-large" style={{backgroundColor: bgColor}}>
				<div style={{ height: "100%", width: "100%", position: "absolute", marginTop: "-15px" }}>{ this.state.series.length > 0 ? this.renderSVG(this.state.series) : "" }</div>
				<div>
					<div className="tile-main">{this.props.value}<sup className={sizeUnits ? 'unit' : ''}>{this.props.units}</sup></div>
					{this.props.subtext ? <div className="tile-subtext">{this.props.subtext}</div> : ''}
				</div>
			</div>
		);
	}
	
	renderSVG(SeriesData){

		//create line that preserves high and low points
		for(var i = 0;i < SeriesData.length;++i){
			var thisData = SeriesData[i];
			if(SeriesData.length == 1){
				thisData.graphValue = this.SeriesData.avgValue;
			}
			else if(i == 0){
				var nextData = SeriesData[i + 1];
				if(thisData.maxValue > nextData.maxValue){
					thisData.graphValue = thisData.maxValue;
				}
				else{
					thisData.graphValue = thisData.minValue;
				}
			}
			else if(i == SeriesData.length - 1)
			{
				var prevData = SeriesData[i - 1];
				if(thisData.maxValue > prevData.maxValue){
					thisData.graphValue = thisData.maxValue;
				}
				else{
					thisData.graphValue = thisData.minValue;
				}
			}
			else
			{
				var prevData = SeriesData[i - 1];
				var nextData = SeriesData[i + 1];
				//this value is a peak
				if(thisData.maxValue > prevData.maxValue && thisData.maxValue > nextData.maxValue){
					thisData.graphValue = thisData.maxValue;
				}
				//this value is a trough
				else if(thisData.minValue < prevData.minValue && thisData.minValue < nextData.minValue){
					thisData.graphValue = thisData.minValue;
				}
				else
				{
					thisData.graphValue = thisData.maxValue;
				}
			}
		}

		var svgWidth = 100;
		var svgHeight = 100;

		var min = Math.min(...SeriesData.map(s => s.graphValue));
		var max = Math.max(...SeriesData.map(s => s.graphValue));

		if(min > this.props.min){
			min = this.props.min;
		}
		
		if(max < this.props.max){
			max = this.props.max;
		}

		var startTime = new Date();
		var endTime = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 23, 59, 59, 0);

		//start coords
		var coords = [[0,svgHeight]];

		//line coords
		SeriesData.forEach(d => coords.push([
			MapValue(svgWidth, 0, endTime.getTime(), SeriesData[0].parsedTime.getTime(), d.parsedTime.getTime()), 
			svgHeight - MapValue(svgHeight, 0, max, min, d.graphValue)
		]));

		//add end coords
		coords.push([MapValue(svgWidth, 0, endTime.getTime(), SeriesData[0].parsedTime.getTime(), SeriesData[SeriesData.length - 1].parsedTime.getTime()), svgHeight]);

		return (
			<svg style={{ width: "100%", height: "100%" }} viewBox={`0 0 ${svgWidth} ${svgHeight}`} preserveAspectRatio="none">
				<polygon points={ coords.map(coord => coord.join(',')).join(' ') } fill="rgba(255,255,255,0.2)" stroke="none" />
			</svg>
		);

		function MapValue(HighMap, LowMap, HighValue, LowValue, Value){
			var i = ((HighMap - LowMap) / (HighValue - LowValue));
        	return LowMap + (i * (Value - LowValue));
		}
	}
}