function MakeRequest(url, OnResult){
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200) {
            OnResult(xhr.response);            
        }
    };
    xhr.send();
}

export class API{

    static _GetStartTime(){
        var startTime = new Date();
        startTime = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(), 0, 0, 0, 0);
        return startTime.getUTCFullYear() + "-" + (startTime.getUTCMonth() + 1) + "-" + startTime.getUTCDate() + " " + startTime.getUTCHours() + ":" + startTime.getUTCMinutes() + ":00";
    }

    static GetWeatherSummary(DeviceID, OnResult){
        
        var url = `https://api.weather.mountaindragon.net/summary/latest?deviceid=${DeviceID}&starttime=${encodeURI(API._GetStartTime())}`;
        MakeRequest(url, OnResult);
    }

    static GetSeriesData(DeviceID, DataType, OnResult){
        var url = `https://api.weather.mountaindragon.net/seriesdata/${DataType}/${DeviceID}?starttime=${encodeURI(API._GetStartTime())}`;
        MakeRequest(url, OnResult);
    }
}